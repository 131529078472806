var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"card user-profile o-hidden mb-30"},[_c('div',{staticClass:"header-cover"}),_c('button',{staticClass:"btn btn-float btn-primary ripple",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.onAdd($event)}}},[_vm._v(" Toevoegen ")]),_c('div',{staticClass:"clearfix"}),_vm._m(0),_c('b-card',{staticClass:"wrapper"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"search-options":{
          enabled: false,
        },"pagination-options":{
          enabled: true,
          mode: 'records',
          nextLabel: 'volgende',
          prevLabel: 'vorige',
          rowsPerPageLabel: 'Aantal per pagina',
          ofLabel: 'van',
          pageLabel: 'pagina', // for 'pages' mode
          allLabel: 'Alles',
        },"sort-options":{
          enabled: true,
          initialSortBy: { field: 'lastName', type: 'asc' },
        },"style-class":"tableOne vgt-table table-striped table-hover","rows":_vm.rows},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'open')?_c('span',[(props.row.open === -1)?_c('b-icon',{staticStyle:{"color":"#888"},attrs:{"icon":"circle-fill"}}):_vm._e(),(props.row.open > 0)?_c('b-icon',{attrs:{"icon":"circle-fill","variant":"danger"}}):_vm._e(),(props.row.open === 0)?_c('b-icon',{attrs:{"icon":"circle-fill","variant":"success"}}):_vm._e()],1):(props.column.field == 'status')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatUserStatus")(props.row.status))+" ")]):(props.column.field === 'updated')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.updated))+" ")]):(props.column.field === 'email')?_c('span',[_vm._v(" "+_vm._s(props.row.email)+" ")]):(props.column.field === 'user')?_c('span',[_vm._v(" "+_vm._s((props.row.user || {}).displayName)+" ")]):(props.column.field === 'roles')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatRoles")(props.row.roles))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info"},[_c('img',{staticClass:"profile-picture avatar-lg mb-2",attrs:{"src":require("@/assets/images/default.png"),"alt":""}}),_c('p',{staticClass:"m-0 text-24"},[_vm._v(" Kernteam ")])])}]

export { render, staticRenderFns }