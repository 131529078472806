<template>
  <div class="main-content">
    <div class="card user-profile o-hidden mb-30">
      <div class="header-cover" />
      <button
        type="button"
        class="btn btn-float btn-primary ripple"
        @click.prevent="onAdd"
      >
        Toevoegen
      </button>
      <div class="clearfix" />
      <div class="user-info">
        <img
          class="profile-picture avatar-lg mb-2"
          src="@/assets/images/default.png"
          alt=""
        >
        <p class="m-0 text-24">
          Kernteam
        </p>
      </div>

      <b-card class="wrapper">
        <vue-good-table
          :columns="columns"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            nextLabel: 'volgende',
            prevLabel: 'vorige',
            rowsPerPageLabel: 'Aantal per pagina',
            ofLabel: 'van',
            pageLabel: 'pagina', // for 'pages' mode
            allLabel: 'Alles',
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'lastName', type: 'asc' },
          }"
          style-class="tableOne vgt-table table-striped table-hover"
          :rows="rows"
          @on-row-click="onRowClick"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'open'">
              <b-icon
                v-if="props.row.open === -1"
                icon="circle-fill"
                style="color: #888;"
              />
              <b-icon
                v-if="props.row.open > 0"
                icon="circle-fill"
                variant="danger"
              />
              <b-icon
                v-if="props.row.open === 0"
                icon="circle-fill"
                variant="success"
              />
            </span>
            <span v-else-if="props.column.field == 'status'">
              {{ props.row.status | formatUserStatus }}
            </span>
            <span v-else-if="props.column.field === 'updated'">
              {{ props.row.updated | formatDate }}
            </span>
            <span v-else-if="props.column.field === 'email'">
              {{ props.row.email }}
            </span>
            <span v-else-if="props.column.field === 'user'">
              {{ (props.row.user || {}).displayName }}
            </span>
            <span v-else-if="props.column.field === 'roles'">
              {{ props.row.roles | formatRoles }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { usersService } from '@/services';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Deelnemers',
  },
  data() {
    return {
      columns: [
        {
          label: 'Voornaam',
          field: 'firstName',
          width: '150px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Achternaam',
          field: 'lastName',
          width: '280px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Adres',
          field: 'address',
          width: '150',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Email',
          field: 'email',
          width: '150',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Rol',
          field: 'roles',
          width: '100',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Gewijzigd door',
          field: 'user',
          type: 'string',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Laatst gewijzigd',
          field: 'updated',
          type: 'Date',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['Afgelopen week', 'Afgelopen maand', 'Afgelopen jaar'],
            filterFn: this.dateFilter,
          },
        },
      ],
      rows: [],
    };
  },
  created() {
    usersService.getAll().then((users) => {
      this.rows = users.map((p) => p);
      console.log(this.rows);
    });
  },
  methods: {
    onRowClick(params) {
      this.$router.push(`/users/${params.row._id}`);
    },
    onAdd() {
      this.$router.push('/users/_new');
    },
    userStatusFilter(data, filterString) {
      if (filterString === 'Geen aanvragen') {
        return data === -1;
      }
      if (filterString === 'Open aanvragen') {
        return data > 0;
      }
      if (filterString === 'Aanvragen afgehandeld') {
        return data === 0;
      }
    },
    dateFilter(data, filterString) {
      const dt = moment(data);
      if (filterString === 'Afgelopen week') {
        const weekDT = moment().subtract(7, 'days');
        return dt.isSameOrAfter(weekDT);
      }
      if (filterString === 'Afgelopen maand') {
        const weekDT = moment().subtract(1, 'month');
        return dt.isSameOrAfter(weekDT);
      }
      if (filterString === 'Afgelopen jaar') {
        const weekDT = moment().subtract(1, 'year');
        return dt.isSameOrAfter(weekDT);
      }
    },
  },
};
</script>
<style></style>
